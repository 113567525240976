"use client";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import "@/app/[lang]/styles/swiper.css";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useDispatchSetUseAvailableBalance } from "@/hooks";

export default function HomeSlider({
  bannerDesktopSlides = [],
  bannerMobileSlides = [],
}) {
  const dispatchSetCheckedAvailableBalance =
    useDispatchSetUseAvailableBalance();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Reset the available balance check on mount
  useEffect(() => {
    dispatchSetCheckedAvailableBalance(null);
  }, [dispatchSetCheckedAvailableBalance]);

  return (
    <div className="container p-0 p-md-auto">
      {/* Mobile Swiper */}
      {isMobile && (
        <div className="mt-3">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={true}
            pagination={true}
            modules={[Autoplay, Pagination]}
            className="custom_HomeSwiper"
          >
            {bannerMobileSlides?.map((item, index) => {
              return item?.isDisabled === false ? (
                <SwiperSlide
                  key={`${item.id}-${index}`}
                  className="homepage_swiperslider_div"
                >
                  <Link href={item.link}>
                    <Image
                      src={item?.image}
                      alt={"Mobile Banner"}
                      width={100}
                      height={100}
                      className="homepage_image"
                      priority
                    />
                  </Link>
                </SwiperSlide>
              ) : null;
            })}
          </Swiper>
        </div>
      )}

      {/* Desktop Swiper */}
      {!isMobile && (
        <div className="mb-3">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={true}
            pagination={true}
            modules={[Autoplay, Pagination]}
            className="custom_HomeSwiper"
          >
            {bannerDesktopSlides?.map((item, index) => {
              return item?.isDisabled === false ? (
                <SwiperSlide
                  key={`${item.id}-${index}`}
                  className="homepage_swiperslider_div"
                >
                  <Link href={item.link}>
                    <Image
                      src={item?.image}
                      alt={"Desktop Banner"}
                      width={1250}
                      height={350}
                      className="homepage_image"
                      priority
                    />
                  </Link>
                </SwiperSlide>
              ) : null;
            })}
          </Swiper>
        </div>
      )}
    </div>
  );
}

HomeSlider.propTypes = {
  /**
   * Desktop slide images
   */
  bannerDesktopSlides: PropTypes.array.isRequired,
  /**
   * Mobile slide images
   */
  bannerMobileSlides: PropTypes.array.isRequired,
};
